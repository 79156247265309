import { PrismicNextImage } from '@prismicio/next';
import { FilledLinkToWebField } from '@prismicio/types';
import classNames from 'classnames';
import CtaLink from 'components/CtaLink';
import MissingRequiredFields from 'components/MissingRequiredFields';
import ReviewBadge from 'components/reviewBadge';
import { useScrollToSlice } from 'hooks/useScrollToSlice';
import { HeroBannerSlice } from 'types.generated';
import { getResponsiveImgHeight } from 'util/getResponsiveImgSize';

import styles from './HeroBanner.module.scss';

type HeroBannerProps = {
  slice: HeroBannerSlice;
  context: { navOffset: number };
};

export const HeroBanner = ({ slice, context }: HeroBannerProps) => {
  const {
    title,
    description,
    image,
    primary_cta_text: primaryCtaText,
    primary_cta_link: primaryCtaLink,
    secondary_cta_link: secondaryCtaLink,
    secondary_cta_text: secondaryCtaText,
    has_background_color: hasBackgroundColor,
    cta_link_id: ctaLinkId,
  } = slice.primary;

  const ctaStyles = `w100 p-p fw-bold ta-center c-pointer br8 ${styles.cta}`;
  const primaryCtaStyles = `${ctaStyles} ${
    hasBackgroundColor ? styles.greyPrimaryCta : 'p-btn--primary'
  }`;
  const secondaryCtaStyles = `${ctaStyles} ${
    hasBackgroundColor
      ? // Ideally we shouldn't use "primary" class to style secondary. This is a temporary solution as an update to a neutral button names are coming soon.
        `p-btn--primary ${styles.greySecondaryCta}`
      : `p-btn--secondary ${styles.purpleSecondaryCta}`
  }`;

  const { navOffset } = context;
  const sectionId = 'hero-banner-slice';

  useScrollToSlice(sectionId, navOffset);

  /* We calculate the image height based on the max width we know the image will have on the page, and pass the dimensions to PrismicNextImage:
  https://nextjs.org/docs/pages/building-your-application/optimizing/images#image-sizing */
  const RESIZED_IMAGE_WIDTH = 400;
  const responsiveHeight = getResponsiveImgHeight(
    RESIZED_IMAGE_WIDTH,
    image.dimensions
  );

  if (!title || !image.url) return <MissingRequiredFields />;

  return (
    <section
      id={sectionId}
      className="d-flex jc-between ai-center gap40 fd-column"
    >
      <div
        className={classNames(
          'd-flex jc-between ai-center gap40 p24 br8 mx16',
          styles.container,
          {
            [styles.containerWithBackground]: hasBackgroundColor,
            [styles.containerWithoutBackground]: !hasBackgroundColor,
          }
        )}
      >
        <div>
          <h1 className="p-h1--xl p--serif">{title}</h1>
          <p className="p-p fw-bold mt16">{description}</p>
          <div className={`my24 d-flex gap16 ${styles.buttonContainer}`}>
            {/* TODO: Add other link type use cases */}
            {primaryCtaLink.link_type === 'Web' && primaryCtaText && (
              <CtaLink
                href={(primaryCtaLink as FilledLinkToWebField).url}
                className={primaryCtaStyles}
                target={(primaryCtaLink as FilledLinkToWebField).target}
                ctaLinkId={ctaLinkId as string}
              >
                {primaryCtaText}
              </CtaLink>
            )}
            {secondaryCtaLink.link_type === 'Web' && secondaryCtaText && (
              <CtaLink
                href={(secondaryCtaLink as FilledLinkToWebField).url}
                className={secondaryCtaStyles}
                target={(secondaryCtaLink as FilledLinkToWebField).target}
              >
                {secondaryCtaText}
              </CtaLink>
            )}
          </div>
        </div>
        <div className={styles.image}>
          <PrismicNextImage
            field={image}
            width={RESIZED_IMAGE_WIDTH}
            height={responsiveHeight}
            priority
          />
        </div>
      </div>
      <ReviewBadge />
    </section>
  );
};
