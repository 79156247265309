import { useEffect } from 'react';

export const useScrollToSlice = (elementId: string, navOffset: number) => {
  useEffect(() => {
    const sectionWithLink = document.getElementById(elementId);

    if (!sectionWithLink) return;

    const goToSlice = (targetSlice: HTMLElement | null) => {
      if (targetSlice) {
        const offSet = targetSlice.offsetTop - navOffset;

        window.scrollTo({
          top: offSet,
          behavior: 'smooth',
        });
      }
    };

    const handleClickToSlice = (event: MouseEvent) => {
      const clickedElement = event.target as HTMLElement;
      if (
        clickedElement.tagName === 'A' &&
        clickedElement instanceof HTMLAnchorElement
      ) {
        const url = new URL(clickedElement.href, window.location.href);
        const isSamePage =
          url.hostname === window.location.hostname &&
          url.pathname === window.location.pathname;

        /* We enable smooth scrolling when the link target in the slice anchor element is within the same page and not an external url */
        if (url.hash && isSamePage) {
          event.preventDefault();
          const targetSliceId = url.hash.substring(1);
          const targetSlice = document.getElementById(targetSliceId);
          goToSlice(targetSlice);
        }
      }
    };

    sectionWithLink.addEventListener('click', handleClickToSlice);

    return () =>
      sectionWithLink.removeEventListener('click', handleClickToSlice);
  }, [elementId, navOffset]);
};
